<template>
  <div>
    <v-btn outlined text color="info" class="" to="/schools">
      <v-icon class="mr-2">{{ icons.mdiArrowLeft }}</v-icon>
      {{ $t('Volver') }}
    </v-btn>
    <div v-if="!notFound" class="d-flex justify-space-around mt-2">
      <span v-for="(data, idx) in schoolData" :key="idx">
        {{ data }}
      </span>
    </div>
    <v-card v-if="!notFound">
      <v-card-text>
        <v-card-title class="text-h6 grey lighten-2 justify-center" style="position: relative;">
          {{$t('Resultados generales')}}
          <div class="d-flex print-chart-actions">
            <v-btn :disabled="loading" outlined text color="info" class="" @click="printGeneralCharts()">
              {{ $t('Imprimir gráficos') }}
              <v-icon class="ml-2">{{ icons.mdiPrinter }}</v-icon>
            </v-btn>
            <v-btn icon color="warning" :aria-label="$t('Ayuda')" @click="$refs.print_helper_modal.openModal()">
              <v-icon>{{ icons.mdiHelpCircleOutline }}</v-icon>
            </v-btn>
          </div>
        </v-card-title>
          <v-divider class="mb-3"></v-divider>
          <v-row class="mb-1">
            <v-col cols="12" md="4">
              <div>{{ $t('Evaluaciones') }}:</div>
              <div style="max-height: 260px; height: 100%" class="general-description flex-column overflow-auto">
                <div class="d-flex align-center mt-2" v-for="(evaluation, idx) in allEvaluationsList.slice().reverse()" :key="idx">
                  <v-checkbox
                  class="mt-0 pt-0"
                  dense hide-details
                  v-model="evalCheckbox[allEvaluationsList.length - idx - 1]"
                  @change="setChartColors()"
                  :label="evaluation.evaluation_name"
                  :true-value="{...evaluation, check:true}"
                  :false-value="{...evaluation, check:false}"
                  ></v-checkbox>
                  <span class="evaluation-badge" :style="{backgroundColor: evaluation.color}">
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <div class="mx-auto">
                <vue-apex-charts
                  v-show="!loading"
                  ref="radial_bar_chart"
                  type="radialBar"
                  height="500"
                  width="500"
                  :options="chartOptions.radialBarChartOptions"
                  :series="chartSeries.totalScoreSeries"
                />
                <v-progress-circular class="ma-8" v-if="loading" indeterminate :size="60"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-title class="justify-center text-h6">{{$t('Puntaje de la categoría')}}</v-card-title>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12" class="apex-barchart-container">
              <vue-apex-charts
                v-show="!loading"
                ref="bar_chart"
                type="bar"
                height="500"
                width="100%"
                :options="chartOptions.barChartOptions"
                :series="chartSeries.generalScoreSeries"
              />
              <div class="d-flex justify-center align-center"  v-if="loading">
                <v-progress-circular class="ma-8" indeterminate :size="60"></v-progress-circular>
              </div>
            </v-col>
            <v-col cols="12" class="apex-radarchart-container">
              <vue-apex-charts
                v-show="!loading"
                ref="radar_chart"
                type="radar"
                height="450"
                width="100%"
                :options="chartOptions.radarChartOptions"
                :series="chartSeries.generalScoreSeries"
              />
              <div class="d-flex justify-center align-center"  v-if="loading">
                <v-progress-circular class="ma-8" indeterminate :size="60"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-title class="justify-center text-h6">{{$t('Distribución de los criterios de valoración')}}</v-card-title>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12" class="apex-radarchart-container">
              <vue-apex-charts
                v-show="!loading"
                ref="principles_radar_chart"
                type="radar"
                height="450"
                width="100%"
                :options="chartOptions.principlesRadarChartOptions"
                :series="chartSeries.principlesRadarChartSeries"
              />
              <div class="d-flex justify-center align-center"  v-if="loading">
                <v-progress-circular class="ma-8" indeterminate :size="60"></v-progress-circular>
              </div>
            </v-col>
            <v-col cols="12" class="apex-columnchart-container">
              <vue-apex-charts
                v-show="!loading"
                ref="column_chart"
                type="bar"
                height="450"
                width="100%"
                :options="chartOptions.columnChartOptions"
                :series="chartSeries.principlesRadarChartSeries"
              />
              <div class="d-flex justify-center align-center"  v-if="loading">
                <v-progress-circular class="ma-8" indeterminate :size="60"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
      </v-card-text>
    </v-card>
    <v-alert
    v-if="notFound"
    type="warning"
    class="mt-4"
    >
      {{ $t('Escuela no encontrada.') }}
    </v-alert>
    <PrintHelperModal :no-overflow-hidden="true" ref="print_helper_modal"/>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import { Printd } from 'printd'
// import {ref} from '@vue/composition-api'
import countriesParserMixin from '@/utils/countriesParserMixin';
import instrumentsParserMixin from '@/utils/instrumentsParserMixin';
import { mdiClose, mdiChevronDown, mdiHelpCircleOutline, mdiPencilOutline, mdiTrashCanOutline, mdiPlus, mdiPrinter, mdiCommentMultipleOutline, mdiCalendar, mdiArrowLeft } from '@mdi/js'
import NotesModal from '@/components/Evaluations/NotesModal.vue';
import PrintHelperModal from '@/components/Evaluations/PrintHelperModal.vue';
import { useUtils } from '@core/libs/i18n'

export default {
  data: () => {
    return {
      loading: false,
      schoolData: [],
      evaluationsList: [],
      evalCheckbox: {},
      allEvaluationsList: [],
      results: {},
      generalDescription: null,
      printer: {},
      showDropdown: [],
      isFirstToggle: true,
      notFound: false,
      chartColors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D'],
    }
  },
  mixins: [countriesParserMixin, instrumentsParserMixin],
  methods: {
    async getSchoolResults() {
      this.loading = true
      try {
        const response = await this.$api.getSchoolResults(this.$route.params.id)
        response.forEach((el, idx) => {
          this.evalCheckbox[idx] = el
          this.evalCheckbox[idx].color = this.chartColors[idx]
          this.evalCheckbox[idx].check = true
        })
        this.evaluationsList = response
        this.allEvaluationsList = response
      } catch(e) {
        console.log(e)
        this.notFound = true
      } finally {
        this.loading = false
      }
    },

    async setSchoolData() {
      try {
        const response = await this.$api.getSchool(this.$route.params.id)
        this.schoolData.push(`${this.$t('Escuela')}: ${response.name}`, `${this.$t('Region')}: ${response.region_name}` ,`${this.$t('País')}: ${this.countryParser[response.country]}`)
      } catch(e) {
        console.log(e)
        this.notFound = true
      }
    },

    async createCharts() {
      this.loading = true
      this.results = {
        program_total: [], 
        category_total: [], 
        all_used_categories: [],
        percentage_total: [], 
        all_used_percentages: [],
        evaluation_labels: [],
        colors: []
      }
      let instrument = -1
      this.copyGeneralDescription()
      try {
        this.evaluationsList.forEach((el, idx) => {
          this.results.colors.push(el.color)
          this.results.program_total.push(el.program_total)
          this.results.evaluation_labels.push(el.evaluation_name)
          this.results.category_total.push({data: el.category_total, name: el.evaluation_name})
          this.results.percentage_total.push({data: el.percentage_total, name: el.evaluation_name})
          this.results.all_used_percentages = [...new Set(this.results.all_used_percentages.concat(el.percentage_total.map(el => el.principle)))]
          this.results.all_used_categories = [...new Set(this.results.all_used_categories.concat(el.category_total.map(el => el.name)))]
        })

        instrument = this.allEvaluationsList[0].instrument

        let categories_parsed = []

        if(instrument === 1) {
          this.results.all_used_categories.forEach((category) => {
            categories_parsed.push(this.autoBreak(this.schoolCategoryParser[category]))
          })
        } else if(instrument === 0) {
          this.results.all_used_categories.forEach((category) => {
            categories_parsed.push(this.autoBreak(this.preschoolCategoryParser[category]))
          })
        }
        
        let percentages_parsed = []

        this.results.all_used_percentages.forEach((percentage) => {
          percentages_parsed.push(this.autoBreak(this.percentagesParser[percentage]))
        })

        this.chartSeries.totalScoreSeries = this.results.program_total
        console.log(this.chartSeries.totalScoreSeries)

        this.chartSeries.generalScoreSeries = this.results.category_total.map(evaluation => {
          return {
            data: evaluation.data.map((el) => ({
              x: this.autoBreak(instrument === 1 ? this.schoolCategoryParser[el.name] : instrument === 0 ? this.preschoolCategoryParser[el.name] : this.schoolCategoryParser[el.name]),
              y: el.value
            })),
            name: evaluation.name
          }
        });

        this.chartSeries.principlesRadarChartSeries = this.results.percentage_total.map(evaluation => {
          return {
            data: evaluation.data.map((el) => ({
              x: this.autoBreak(this.percentagesParser[el.principle]),
              y: el.percentage
            })),
            name: evaluation.name
          }
        })

        this.chartSeries.categoryTotals = this.results.category_total.map(category => [category.value]);

        if (this.$refs.bar_chart) {
          this.$refs.bar_chart.updateSeries(
            this.chartSeries.generalScoreSeries
          ,false,true)
        }

        if (this.$refs.radial_bar_chart) {
          this.$refs.radial_bar_chart.updateSeries([
            this.chartSeries.totalScoreSeries
          ],false,true)
        }

        if (this.$refs.radial_bar_chart) {
          this.$refs.radial_bar_chart.updateOptions({
            labels: this.results.evaluation_labels,
            colors: this.results.colors
          })
        }

        if (this.$refs.bar_chart) {
          this.$refs.bar_chart.updateOptions({
            colors: this.results.colors,
            xaxis: {
              categories: categories_parsed
            }
          })
        }

        if (this.$refs.radar_chart) {
          this.$refs.radar_chart.updateOptions({
            colors: this.results.colors,
            xaxis: {
              categories: categories_parsed
            }
          })
        }

        if (this.$refs.radar_chart) {
          this.$refs.radar_chart.updateSeries(
            this.chartSeries.generalScoreSeries
          ,false,true)
        }

        if (this.$refs.principles_radar_chart) {
          this.$refs.principles_radar_chart.updateSeries(
            this.chartSeries.principlesRadarChartSeries
          ,false,true)
        }

        if (this.$refs.principles_radar_chart) {
          this.$refs.principles_radar_chart.updateOptions({
            colors: this.results.colors,
            xaxis: {
              categories: percentages_parsed
            }
          })
        }

        if (this.$refs.column_chart) {
          this.$refs.column_chart.updateOptions({
            colors: this.results.colors,
            xaxis: {
              categories: percentages_parsed
            }
          })
        }

      } catch(e) {
        console.log(e)
      } finally {
        window.dispatchEvent(new Event('resize'));
        this.loading = false
      }
    },
    resize() {
      window.dispatchEvent(new Event('resize'));
    },

    setChartColors() {
      let newArray = []
      for(const property in this.evalCheckbox) {
        if(this.evalCheckbox[property].check) {
          newArray.push(this.evalCheckbox[property])
          this.evaluationsList = newArray
        }
      }
    },

    formatTotalIncrement(val) {
      let allValues = []
      let total = 0
      this.evaluationsList.forEach((evaluation) => {
        let order = this.allEvaluationsList.findIndex(el => el.evaluation_id === evaluation.evaluation_id)
        allValues.push({order: order, value: evaluation.program_total})
      })

      if(allValues.length > 1) {
        let initialVal = allValues.reduce((prev, curr) => prev.order > curr.order ? prev : curr).value;
        let finalVal = allValues.reduce((prev, curr) => prev.order < curr.order ? prev : curr).value;
        total = 100 * ((finalVal - initialVal) / initialVal)
      } else {
        total = allValues[0] ? allValues[0].value : 0
      }


      if(total % 1 === 0) {
        return `${(total > 0 && allValues.length > 1) ? '+' : ''}` + total + '%'
      } else {
        return `${(total > 0 && allValues.length > 1) ? '+' : ''}` + (total).toFixed(2) + '%'
      }
    },

    autoBreak(label) {
      const maxLength = 14;
      const lines = [];
      if(label) {
        for (let word of label.split(" ")) {
          if (lines.length == 0) {
            lines.push(word);
          } else {
            const i = lines.length - 1
            const line = lines[i]
            if (line.length + 1 + word.length <= maxLength) {
              lines[i] = `${line} ${word}`
            } else {
              lines.push(word)
            }
          }
        }
      }
      return lines;
    },

    printGeneralCharts() {
      const cssText = `
        .show-notes {
          display: none;
        }

        .general-description {
          display:flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 15px;
          margin-bottom: 5px;
          font-family: "AvenirNext", "Inter", sans-serif !important;
          font-size: 15px !important;
        }

        .evaluation-badge {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 5px;
          margin-left: 4px;
          print-color-adjust: exact;
          -webkit-print-color-adjust: exact;
        }

        h2 {
          font-family: "AvenirNext", "Inter", sans-serif !important;
          font-size: 24px;
        }

        .page-break {
          page-break-after: always;
        }

        .apexcharts-toolbar, .apexcharts-tooltip, .apexcharts-yaxistooltip, .apexcharts-legend, .apexcharts-yaxistooltip{
          display: none !important;
          stroke: none !important;
        }

        .apexcharts-ycrosshairs{
          border: none !important;
          stroke: none !important;
        }

        .apexcharts-datalabel, .apexcharts-text {
          fill: #000 !important;
          font-size: 14px;
          font-weight: 700;
        }

      `
      let printContainer = document.createElement('div');
      printContainer.classList.add('charts-container')

      let chartRefs = ['radial_bar_chart', 'bar_chart', 'radar_chart', 'principles_radar_chart', 'column_chart',];

      printContainer.appendChild(this.generalDescription);

      // Crear un nuevo layout para la impresion de los graficos. solo con graficos y titulo
      chartRefs.forEach((ref, index) => {

        let chartContainer = document.createElement('div');
        chartContainer.classList.add("chart-print-container");

        if (ref === 'radial_bar_chart') {
          let title = document.createElement('h2');
          title.textContent = `${this.$t('Resultados generales')}`;
          chartContainer.appendChild(title);
        }

        if (ref === 'bar_chart') {
          let title = document.createElement('h2');
          title.textContent = `${this.$t('Puntaje de la categoría')}`;
          chartContainer.appendChild(title);
        }

        if (ref === 'principles_radar_chart') {
          let title = document.createElement('h2');
          title.textContent = `${this.$t('Distribución de los criterios de valoración')}`;
          chartContainer.appendChild(title);
        }

        // Agregar el gráfico al contenedor
        let chart = this.$refs[ref].$el.cloneNode(true);
        chart.classList.add('chart-element')

        if (ref === 'bar_chart' || ref === 'principles_radar_chart') {
          chart.classList.add('page-break')
        }
        chartContainer.appendChild(chart);

        // Agregar el contenedor del gráfico al contenedor de impresión
        printContainer.appendChild(chartContainer);
      });

      // Imprimir todos los gráficos a la vez
      this.printer.print(printContainer, [cssText])
    },
    copyGeneralDescription() {
      const node = document.createElement('div');

      const school_data = document.createElement('div');
      node.appendChild(school_data)

      this.schoolData.forEach(item => {
        const span = document.createElement('span');
        span.textContent = item;
        school_data.appendChild(span);
      });

      const evaluation_data = document.createElement('div');
      node.appendChild(evaluation_data)


      this.evaluationsList.forEach((item, id) => {
        const container = document.createElement('div')
        container.classList.add('d-flex', 'align-center')
        const span = document.createElement('span');
        span.textContent = item.evaluation_name;
        container.appendChild(span);
        const period = document.createElement('span')
        period.textContent = ` - ${this.$t('Período')}: ${item.evaluation_period}`;
        container.appendChild(period)
        const badge = document.createElement('span');
        badge.style.background = item.color
        badge.classList.add('evaluation-badge')
        container.appendChild(badge)
        evaluation_data.appendChild(container)
      });

      school_data.classList.add('general-description')
      evaluation_data.classList.add('general-description')
      this.generalDescription = node
    },
  },
  watch: {
    evaluationsList(newValue) {
      if(newValue) {
        this.createCharts()
      }
    }
  },
  mounted() {
    this.printer = new Printd()
    this.getSchoolResults()
    this.setSchoolData()
  },
  computed: {
    chartSeries() {
      return {
        totalScoreSeries: [],
        generalScoreSeries: [{ data: [] }],
        principlesRadarChartSeries:  [{ data: [] }],
        categorySeries:[],
        categoryesRadarChartSeries: [
          [{ data:[] }],
        ],
        indicatorsBarSeries: [
          [{ data:[] }],
        ],
        categoryTotals: []
      }
    },
    chartOptions() {
      return {
        radialBarChartOptions: {
          chart: {
            height: 10000,
            type: 'radialBar',
            offsetY: -10,
            sparkline: {
              enabled: true
            },
            toolbar: {
              show:true,
              tools: {
                download: true
              },
              export: {
                png: {
                  filename: undefined
                }
              }
            }
          },
          tooltip: {
            enabled: true,
            theme: 'dark',
            fillSeriesColor: false
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D'],
          plotOptions: {
            radialBar: {
              startAngle: -90,
              endAngle: 90,
              hollow: {
                imageClipped: false
              },
              track: {
                background: "#e7e7e7",
                strokeWidth: '97%',
                margin: 5, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  top: 2,
                  left: 0,
                  color: '#999',
                  opacity: 1,
                  blur: 2
                }
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  offsetY: -2,
                  fontSize: '22px',
                },
                total: {
                  show: true,
                  label: 'Promedio',
                  formatter: this.formatTotalIncrement,
                }
              }
            }
          },
          grid: {
            padding: {
              top: -10
            }
          },
          labels: [],
        },


        barChartOptions: {
          fill: {
            opacity: 1
          },
          noData: {
            text: '0'
          },
          legend: {
            show: false
          },
          stroke: {
            colors: ["transparent"],
            width: 5
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D'],
          chart: {
            type: 'bar',
            height: 500,
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
              horizontal: true,
              barHeight: '80%',
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '14px',
              colors: ['#373636'],
            },
          },
          xaxis: {
            type: 'category',
            labels: {
              style: {
                colors: '#373636',
                // fontSize: '20px',
                // fontWeight: 'bold',
              }
            },
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },

          }
        },

        radarChartOptions: {
          chart: {
            type: 'radar',
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D'],
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
              //colors: ['#373636'],
            },
            background: {
              enabled: true,
              padding: 5,
              borderRadius: 3,
              opacity: 0.85,
            },
          },
          plotOptions: {
            radar: {
              size: 190,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#F5F5F5', '#fff']
                }
              }
            }
          },
          grid: {
            padding: {
              bottom: -60
            }
          },
          legend: {
            position: 'left',
            onItemClick: {
              toggleDataSeries: false
            }
          },
          markers: {
            size: 4,
            colors: ['#fff'],
            strokeColor: '#FF4560',
            strokeWidth: 2,
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val
              }
            }
          },
          xaxis: {
            type: 'category',
            labels: {
              style: {
                fontSize: '14px',
                fontWeight: 'bold',
              }
            }
          },
          yaxis: {
            show: false,
            // labels: {
            //   offsetX: 30,
            //   offsetY: 30,
            // }
          }
        },
        principlesRadarChartOptions: {
          chart: {
            type: 'radar',
          },
          legend: {
            position: 'left',
            onItemClick: {
              toggleDataSeries: false
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: '18px',
              fontWeight: 'bold',
            },
            background: {
              enabled: true,
              padding: 5,
              borderRadius: 3,
              opacity: 0.85,
            },
          },
          plotOptions: {
            radar: {
              size: 190,
              polygons: {
                strokeColors: '#e9e9e9',
                fill: {
                  colors: ['#F5F5F5', '#fff']
                }
              }
            }
          },
          colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D'],
          markers: {
            size: 0,
          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val
              }
            }
          },
          xaxis: {
            type: 'category',
          },
          yaxis: {
            show: false
          }
        },
        columnChartOptions: {
          chart: {
              height: 350,
              type: 'bar',
            },
            stroke: {
              colors: ["transparent"],
              width: 1
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: '80%',
                dataLabels: {
                  position: 'top', // top, center, bottom
                },
              }
            },
            colors: ['#1D4F91', '#FFB81C', '#00BA91', '#84239B', '#E0004D'],
            dataLabels: {
              enabled: true,
              formatter: function (val) {
                return val + "%";
              },
              offsetY: 0,
              style: {
                fontSize: '12px',
                colors: ["#304758"]
              }
            },
            legend: {
              show: false
            },
            xaxis: {
              type: 'category',
              position: 'bottom',
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false
              },
              crosshairs: {
                fill: {
                  type: 'gradient',
                  gradient: {
                    colorFrom: '#D8E3F0',
                    colorTo: '#BED1E6',
                    stops: [0, 100],
                    opacityFrom: 0.4,
                    opacityTo: 0.5,
                  }
                }
              },
              // tooltip: {
              //   enabled: true,
              // }
            },
            yaxis: {
              axisBorder: {
                show: false
              },
              axisTicks: {
                show: false,
              },
              labels: {
                show: false,
                formatter: function (val) {
                  return val + "%";
                }
              }

            },
        },
      }
    },
    percentagesParser() {
      const parser = {
        1: this.$t('Prácticas-autonomía'),
        2: this.$t('Trabajo colaborativo'),
        3: this.$t('Comunicación transversal'),
        4: this.$t('Inclusión educativa'),
      }
      return parser
    },
  },
  components: {
    VueApexCharts,
    NotesModal,
    PrintHelperModal,
  },
  setup() {
    const { t } = useUtils()

    const icons = {
      mdiClose,
      mdiChevronDown,
      mdiHelpCircleOutline,
      mdiPencilOutline,
      mdiTrashCanOutline,
      mdiPlus,
      mdiPrinter,
      mdiCommentMultipleOutline,
      mdiCalendar,
      mdiArrowLeft
    }

    return {
      t,
      icons,
    }
  }
}
</script>

<style scoped lang="scss">

.hidden-indicators {
  visibility: hidden;
  position: absolute;
}

.margins-breakpoints {
  margin-right: auto;
}

.evaluation-badge {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  margin-left: 4px;
  print-color-adjust: exact;
  -webkit-print-color-adjust: exact;
}

.only-print{
  display: none;
}
.print-chart-actions {
  position: absolute;
  left: 75%;
}

@media (max-width: 960px) {
  .general-description {
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 90px !important;
  }
  .general-description div {
    margin: 0 4px
  }
}

@media (min-width: 1264px) {
  .margins-breakpoints {
    margin-right: initial;
  }
}

.graphics-border {
  border-right: solid 1px rgba(94, 86, 105, 0.14);
}

.row-with-border {
  border-top: 2px solid rgba(94, 86, 105, 0.14);
  border-bottom: 2px solid rgba(94, 86, 105, 0.14);
  padding: 8px 0px;
}

th, td {
  width: 33%;
  border-right: solid 1px rgba(94, 86, 105, 0.14);
}
.active-tab
{
  background-color: #4d80c4;
}

</style>
